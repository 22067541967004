#egypt_map {
  padding: 10px;
  width: 100%;
  transition: 1s all ease-in-out;
}

#egypt_map svg {
  width: 100%;
  height: 100%;
  fill: var(--SecondaryColor);
  stroke: var(--whiteColorDeam);
  stroke-width: 2;
}

#egypt_map svg path {
  cursor: pointer;
}

#egypt_map svg *:hover,
.selected_location {
  fill: var(--PrimaryColor);
}
